:root {
  /* Common Colors */
  --color-blue: #00b4e8;
  --color-green: #4db04a;
  --color-yellow: #ffd400;
  --color-darkest-gray: #363636;
  --color-gray: #6D6D6D;
  --color-medium-gray: #A4A4A4;
  --color-light-gray: #D5D5D5;
  --color-lightest-gray: #F2F4F5;
  --nav-width: 320px;
  --color-content-bg: #f5f5f5;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.page-header {
  font-size: 28px;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.flex-wrap {
  flex-wrap: wrap;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
